<template>
  <div class="organizations-head-section__title row">
    <div class="col-58">
      <h1 class="organizations-head-section__title">
        Каталог образования
      </h1>
      <div class="organizations-head-section__description">
        Развивайте врожденные способности и приобретайте новые с помощью образовательных программ
      </div>
    </div>
    <OrganizationsCounters class="col-41"/>
  </div>
</template>

<script>
export default {
  name: 'OrganizationsHeadSection',

  methods: {},
};
</script>
